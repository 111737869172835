<template>
  <div :class="CCODE">
    <van-overlay :show="loading"
                 z-index="99">
      <van-loading style="margin-top: 280px"
                   size="30px"
                   vertical>加载中...</van-loading>
    </van-overlay>
    <van-image v-if="CCODE=='mianyi'"
               :src="mianyi" />
    <van-image v-if="CCODE=='kangshuai'"
               :src="kangshuai" />
    <van-image v-if="CCODE=='kangyang'"
               :src="kangyang" />
    <div class="container">
      <div class="block"
           v-for="(v, i) in list"
           :key="i">
        <div class="left"><span>{{ v.CDESC }}</span></div>
        <div class="right"
             :class="{ check: !v.show && v.STATUS != 'B' }">
          <div v-show="v.show">
            <div @click="view(v, i)"
                 v-html="v.MESSAGE"></div>
            <div class="extra-content">
              <!-- <div class="title-btn"
                   v-if="img.filename">
                <img src="~@/images/report.png" />
                <div>{{img.filename}}</div>
              </div>
              <van-image v-if="img.showLink"
                         :src="img.showLink" /> -->
              <van-uploader width="100%"
                            :file-list="v.imgs"
                            :deletable="false"
                            :show-upload="false" />
            </div>
          </div>
          <div class="align-center"
               @click="view(v, i)">
            {{ v.show ? "收起" : (v.STATUS == "B" ? "已完成" : "未开始") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { YZ110P_2B, getLinkArr } from '@/api'
import mianyi from '@/images/mianyi.png'
import kangshuai from '@/images/kangshuai.png'
import kangyang from '@/images/kangyang.png'

export default {
  name: 'lpky110p',
  data () {
    return {
      list: [],
      loading: false,
      mianyi,
      kangshuai,
      kangyang,
      CCODE: ''
    }
  },
  methods: {
    getBarner (CCODE) {
      let name = ''
      switch (CCODE) {
        // 免疫细胞回输
        case 'LPKY110P_1':
          name = 'mianyi'
          break
        // 干细胞回输
        case 'LPKY110P_2':
          name = 'kangyang'
          break
        // 干细胞面部保养
        case 'LPKY110P_3':
          name = 'kangshuai'
          break
      }
      return name
    },
    getLink (data) {
      return new Promise((resolve, reject) => {
        let linkArr = data.map(v => {
          v = {
            ID: v.ID,
            filename: v.FILE_NAME,
            path: v.FILE_PATH
          }
          return v
        })
        if (linkArr && linkArr.length) {
          getLinkArr({ linkArr }).then(({ data = [] }) => {
            let fileData = data.map(v => {
              v['url'] = v.showLink
              return v
            })
            resolve(fileData)
          }).catch(() => {
            reject()
          })
        } else {
          resolve([])
        }
      }).catch(() => console.log('Oops errors!'))
    },
    getData () {
      let ID = this.$route.query.ID
      let CCODE = this.$route.query.CCODE
      if (ID && CCODE) {
        this.loading = true
        this.CCODE = this.getBarner(this.$route.query.CCODE || '')
        YZ110P_2B({ ID }).then(async ({ data = [] }) => {
          let list = data.length ? data[0].servicedetail : []
          let arr = list.filter(v => v.STATUS === 'B')
          let result = []
          for (let i = 0; i < list.length; i++) {
            let obj = list[i]
            obj['show'] = i === arr.length - 1
            obj['imgs'] = await this.getLink(list[i].files || [])
            result.push(obj)
          }
          this.list = result
          this.loading = false
        }).catch(err => {
          this.loading = false
          this.$toast(err.message)
        })
      } else {
        // 参数不对
        this.$router.push('/')
      }
    },
    view (v, i) {
      this.list[i].show = v.STATUS === 'A' ? false : !v.show
      this.list = [...this.list]
    }
  },
  created () {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
@import "~@/style/common.less";
.mianyi {
  background: #f4e8e8;
  .container {
    background: url(../../images/my-bg.png) no-repeat;
    background-size: cover;
    .block {
      .left {
        background: linear-gradient(to right, #5386bd, #6eafc1);
      }
      .right {
        .extra-content {
          .title-btn {
            background: linear-gradient(to right, #5285bc, #6eb0c0);
          }
        }
      }
      .check {
        background: linear-gradient(to right, #5285bc, #6dafbf);
      }
    }
  }
}

.kangyang {
  background: #f0f1f5;
  .container {
    background: url(../../images/ky-bg.png) no-repeat;
    background-size: cover;
    .block {
      .left {
        background: linear-gradient(to right, #ecadbe, #f394b3);
      }
      .right {
        .extra-content {
          .title-btn {
            background: linear-gradient(to right, #ecadbe, #f396b5);
          }
        }
      }
      .check {
        color: #000;
      }
    }
    .block::nth-child(2n + 1) {
      .title-btn {
        background: #ecf0fc;
        color: #4677f7;
      }
    }
  }
}

.kangshuai {
  background: #f0f1f5;
  .container {
    background: url(../../images/ksh-bg.png) no-repeat;
    background-size: cover;
    .block {
      .left {
        background: linear-gradient(to right, #ea926e, #fcaf95);
      }
      .check {
        color: #000;
      }
    }
  }
}

/deep/ .van-uploader__preview {
  margin: 0;
  width: 100%;
}
/deep/ .van-uploader__preview-image {
  width: 100%;
  height: auto;
}
</style>
